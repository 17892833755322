<template>
  <SuppliersTable />
</template>

<script>
import SuppliersTable from "../components/SuppliersTable";

export default {
  name: "Suppliers",
  components: {
    SuppliersTable,
  },
};
</script>