<template>
  <v-dialog v-model="new_supplier_dialog" width="850">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        ref="button"
        elevation="2"
        color="#33cc99"
        dark
        class="ml-10"
        v-bind="attrs"
        v-on="on"
      >
        <v-icon left>mdi-plus</v-icon>
        Add Supplier
      </v-btn>
    </template>
    <NewSupplierModal @closeDialog="closeDialog($event)" />
  </v-dialog>
</template>

<script>
export default {
  name: "NewSupplierButton",
  props: [],
  components: {
    NewSupplierModal: () => import("./NewSupplierModal"),
  },
  data() {
    return {
      new_supplier_dialog: false,
    };
  },
  methods: {
    closeDialog(new_supplier_dialog) {
      this.new_supplier_dialog = new_supplier_dialog;
    },
  },
};
</script>