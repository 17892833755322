//----------------------------------------------
//DATA TABLE HEADERS
//Customers  table
const suppliersTableHeaders = [
    { text: 'Edit', value: 'supplier_id', align: 'start' },
    { text: 'Company Name', sortable: true, value: 'supplier_name' },
    { text: 'VAT #', sortable: true, value: 'supplier_VAT_number' },
    { text: 'Contact Person', value: 'supplier_contact_person_name' },
    { text: 'Address Line 1', value: 'supplier_addr_1' },
    { text: 'Address Line 2', value: 'supplier_addr_2' },
    { text: 'City', value: 'supplier_city' },
    { text: 'Area Code', value: 'supplier_zip' },
    { text: 'Date Added', value: 'supplier_date_created' },
    { text: 'Status', value: 'supplier_status' }
]

//EXPORTED to the Vuex state block
export {
    suppliersTableHeaders
}