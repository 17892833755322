var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"mb-8"},[_c('v-col',{attrs:{"lg":"12"}},[_c('v-card-title',[_c('h2',[_vm._v("Suppliers")]),(_vm.firstLoad)?_c('v-skeleton-loader',{staticClass:"ml-10",attrs:{"loading":_vm.loading_user,"type":"button"}}):_vm._e(),(!_vm.firstLoad)?_c('NewSupplierButton',{directives:[{name:"show",rawName:"v-show",value:(this.user_roles_list.flat().includes('suppliers_write')),expression:"this.user_roles_list.flat().includes('suppliers_write')"}]}):_vm._e()],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-sheet',{attrs:{"elevation":"4"}},[[_c('div',[(_vm.firstLoad)?_c('v-skeleton-loader',{attrs:{"loading":_vm.loading_user,"type":"table","max-height":"550"}}):_vm._e(),(!_vm.firstLoad)?_c('v-data-table',{attrs:{"headers":_vm.suppliersTableHeaders,"items":_vm.suppliers,"search":_vm.search},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-spacer'),_c('v-col',{attrs:{"lg":"4","md":"4"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":"","rounded":"","dense":"","filled":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1)]},proxy:true},(
                    this.user_roles_list.flat().includes('suppliers_write')
                  )?{key:"item.supplier_id",fn:function(ref){
                  var item = ref.item;
return [_c('v-btn',{attrs:{"to":{
                      name: 'edit-supplier',
                      params: { supplier_id: item.supplier_id },
                    },"color":"#3d2cdd","icon":"","small":""}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-pencil")])],1)]}}:{key:"item.supplier_id",fn:function(){return [_c('v-btn',{attrs:{"color":"#3d2cdd","icon":"","small":"","disabled":""}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-pencil-off")])],1)]},proxy:true},{key:"no-data",fn:function(){return [_c('v-container',[_c('v-row',[_c('v-col',[_c('div',{staticClass:"my-3"},[_c('h3',[_vm._v("Welcome To Your Supplier Dashboard")]),_c('p',[_vm._v(" Here you can add new suppliers, or view and edit"),_c('br'),_vm._v(" the details of ones already in your system ")]),_c('NewSupplierButton')],1)])],1)],1)]},proxy:true}],null,true)}):_vm._e()],1)]],2)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }