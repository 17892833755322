import Vue from 'vue';
import Vuex from 'vuex';
//Import component state values
import {
    //BUTTONS

    //DATA TABLE HEADERS
    suppliersTableHeaders,
    //FORM HEADERS

} from '../data/data'

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        //BUTTONS

        //DATA TABLE HEADERS
        suppliersTableHeaders,
        //FORM HEADERS

    },
    mutations: {},
    actions: {},
    modules: {},
});