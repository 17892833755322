<template>
  <div>
    <v-container fluid>
      <v-row class="mb-8">
        <v-col lg="12">
          <v-card-title>
            <h2>Suppliers</h2>
            <!--Skeleton button-->
            <v-skeleton-loader
              :loading="loading_user"
              type="button"
              v-if="firstLoad"
              class="ml-10"
            >
            </v-skeleton-loader>
            <!--Button Options modal-->
            <NewSupplierButton
              v-show="this.user_roles_list.flat().includes('suppliers_write')"
              v-if="!firstLoad"
            />
          </v-card-title>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-sheet elevation="4">
            <!--Suppliers data table-->
            <template>
              <div>
                <v-skeleton-loader
                  :loading="loading_user"
                  type="table"
                  max-height="550"
                  v-if="firstLoad"
                >
                </v-skeleton-loader>
                <v-data-table
                  :headers="suppliersTableHeaders"
                  :items="suppliers"
                  :search="search"
                  v-if="!firstLoad"
                >
                  <template v-slot:top>
                    <v-container fluid>
                      <v-row>
                        <v-spacer />
                        <!--Search bar filter -->
                        <v-col lg="4" md="4">
                          <v-text-field
                            v-model="search"
                            append-icon="mdi-magnify"
                            label="Search"
                            single-line
                            hide-details
                            rounded
                            dense
                            filled
                            clearable
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </template>
                  <!--Edit Button-->
                  <template
                    v-slot:item.supplier_id="{ item }"
                    v-if="
                      this.user_roles_list.flat().includes('suppliers_write')
                    "
                  >
                    <v-btn
                      :to="{
                        name: 'edit-supplier',
                        params: { supplier_id: item.supplier_id },
                      }"
                      color="#3d2cdd"
                      icon
                      small
                      ><v-icon small>mdi-pencil</v-icon></v-btn
                    >
                  </template>
                  <!--Disable edit button based on user roles-->
                  <template v-slot:item.supplier_id v-else>
                    <v-btn color="#3d2cdd" icon small disabled
                      ><v-icon small>mdi-pencil-off</v-icon></v-btn
                    >
                  </template>
                  <!--Empty datatable slot-->
                  <template v-slot:no-data>
                    <v-container>
                      <v-row>
                        <v-col>
                          <div class="my-3">
                            <h3>Welcome To Your Supplier Dashboard</h3>
                            <p>
                              Here you can add new suppliers, or view and
                              edit<br />
                              the details of ones already in your system
                            </p>
                            <NewSupplierButton />
                          </div>
                        </v-col>
                      </v-row>
                    </v-container>
                  </template>
                </v-data-table>
              </div>
            </template>
          </v-sheet>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import db from "../../../components/firebaseInit"; //imports firebase database that was specified in firebaseInit.js
import mixin_UserRoles from "../../../globalActions/mixin_UserRoles";
import store from "../store/store";
import { mapState } from "vuex";
import NewSupplierButton from "../components/NewSupplierButton";

export default {
  components: { NewSupplierButton },
  mixins: [mixin_UserRoles],
  name: "SuppliersTable",
  data() {
    return {
      pagination: {},
      search: "",
      suppliers: [],
    };
  },
  // Gets suppliers from firebase datatbase
  created() {
    db.collection("suppliers").onSnapshot((querySnapshot) => {
      this.suppliers = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        this.suppliers.push(data);
      });
    });
  },
  store,
  computed: mapState({
    suppliersTableHeaders: (state) => state.suppliersTableHeaders,
  }),
  methods: {},
};
</script>

<style scoped>
.white-text {
  color: #fff;
}
</style>